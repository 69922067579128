<template>
    <div>
        <SelectButton v-model="reportType" :options="['Geral','Bolao']" @change="loadSessions"></SelectButton>
        <div class="header-container">
            <div class="button-set search p-input-icon-left">
                <input class="p-inputtext" type="text" v-model="filter.name" placeholder="Filtrar"/>
                <i class="pi pi-search"></i>
            </div>
            <div class="button-set">
                <div>Imprimir</div>
                <Button icon="pi pi-print" class="p-button-sm" iconPos="right"  @click="visible.print = true;print.reference = 'balance'"/>
            </div>
        </div>

        <!-- Relatorio Principal -->

        <DataTable @sort="onSort" :totalRecords="totalRecords" @page="onPage($event)" :lazy="product() == 'BU' ? true : false" :rows="perpage" :rowsPerPageOptions="[50, 100, 150, 500]" 
            stripedRows :value="filteredSessions" autoLayout :loading="loading.table" responsiveLayout="scroll" :paginator="product() == 'BU'"
            currentPageReportTemplate="Exibindo {first} à {last} de {totalRecords} registros">
            <template #empty>
                <div style="padding: 10px;text-align:center">Nenhum dado encontrado.</div>
            </template>
            <template #loading>
                <Skeleton height="30px" style="margin: 15px 0"/>
                <Skeleton height="30px" style="margin: 15px 0"/>
                <Skeleton height="30px" style="margin: 15px 0"/>
            </template>
            <Column field="name" header="Nome" sortable ></Column>
            <Column field="balance" header="Saldo" sortable :footer="toMoney(totalBalance)">
                <template #body="session">
                    <span :class="{'negative':session.data.balance < 0}">{{ toMoney(session.data.balance) }}</span>
                </template>
            </Column>
            <Column>
                <template #body="session">
                    <div v-if="product() == 'BU' && reportType === 'Geral'" class="buttons">
                        <Button class="p-button-sm" @click="showPaymentGuide(session.data)" icon="pi pi-info-circle" :disabled="session.data.balance == 0"/>
                        <Button class="p-button-sm" @click="showPostBalance(session.data)" icon="pi pi-dollar" :disabled="session.data.balance == 0"/>
                    </div>
                    <Button v-else class="p-button-sm" @click="showPostBalance(session.data)" icon="pi pi-dollar" :disabled="session.data.balance == 0"/>
                </template>
            </Column>
        </DataTable>

        <!-- Modal para ver a guia de pagamento do cambista  -->
        <SideBar :showCloseIcon="false" v-model:visible="visible.paymentGuide" position="bottom" class="payment-guide">
            <div class="payment-guide-container">
                <div class="header">
                    <div><span><i class="pi pi-user" style="font-size:15px"></i>{{paymentGuide.session.name}}</span></div>
                    <div>
                        <i v-if="paymentGuide.selectedDate" @click="visible.print = true;print.reference = 'guide'" class="pi pi-print"></i>
                        <i @click="visible.paymentGuide = false" class="pi pi-times"></i>
                    </div>
                </div>
                <div class="content">
                    
                    <div class="top">
                        <div v-if="!paymentGuide.selectedDate">Selecione uma data </div>
                        <div v-else>Selecionado: {{new Date(paymentGuide.date)?.toLocaleDateString()}} </div>
                        <div><Button icon="pi pi-calendar" @click="paymentGuide.calendarVisible = !paymentGuide.calendarVisible"/></div>
                    </div>
                    <Transition name="grow">
                        <Calendar @date-select="loadPointGuide" dateFormat="yy-mm-dd" v-if="paymentGuide.calendarVisible" v-model="paymentGuide.date" :inline="true" :maxDate="new Date()"/>
                    </Transition>
                    <div v-show="this.paymentGuide.loading" style="text-align:center;padding: 10px 0">
                        <i style="font-size: 25px" class="pi pi-spinner pi-spin"></i>
                    </div>
                    <div v-if="this.paymentGuide.selectedDate && !this.paymentGuide.loading">
                        <div>
                            <h4>VENDAS</h4>
                            <DataTable stripedRows :value="paymentGuide.data.vendaPorExtracao" autoLayout responsiveLayout="scroll">
                                <template #empty>
                                    <div class="empty">Nenhum dado encontrado.</div>
                                </template>
                                <Column field="name" header="Extração"/>
                                <Column field="comissao_total" header="Comissão">
                                    <template #body="item">
                                        {{toMoney(item.data.comissao_total)}}
                                    </template>
                                </Column>
                                <Column field="venda_total" header="Valor">
                                    <template #body="item">
                                        {{toMoney(item.data.venda_total)}}
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                        <br>
                        <div>
                            <h4>PRÊMIOS</h4>
                            <DataTable stripedRows :value="paymentGuide.data.premiadas" autoLayout responsiveLayout="scroll">
                                <template #empty>
                                    <div class="empty">Nenhum dado encontrado.</div>
                                </template>
                                <Column field="name" header="Extração"/>
                                <Column field="id" header="Ticket"/>
                                <Column field="total_value" header="Prêmio">
                                    <template #body="item">
                                        {{toMoney(item.data.total_value)}}
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                        <br>
                        <div class="resumo">
                            <h4>RESUMO</h4>
                            <div>
                                <div>
                                    <div>Dev Anterior</div>
                                    <div>{{toMoney(paymentGuide.data.dev_anterior)}}</div>
                                </div>
                                <div>
                                    <div>Vendas</div>
                                    <div>{{toMoney(paymentGuide.data.vendas)}}</div>
                                </div>
                                <div>
                                    <div>Cancelamento</div>
                                    <div>{{toMoney(paymentGuide.data.cancelamentos)}}</div>
                                </div>
                                <div>
                                    <div>Comissão</div>
                                    <div>{{toMoney(paymentGuide.data.comissao)}}</div>
                                </div>
                                <div>
                                    <div>Prêmios</div>
                                    <div>{{toMoney(paymentGuide.data.premios)}}</div>
                                </div>
                                <div>
                                    <div>Cana</div>
                                    <div>{{toMoney(paymentGuide.data.cana)}}</div>
                                </div>
                                <!-- <div> -->
                                    <!-- <div>Lucro Bruto</div> -->
                                    <!-- <div>{{toMoney(paymentGuide.data.lucro_bruto)}}</div> -->
                                <!-- </div> -->
                                <div>
                                    <div>Reclamação</div>
                                    <div>{{toMoney(paymentGuide.data.reclamacao)}}</div>
                                </div>
                                <div>
                                    <div>Despesa</div>
                                    <div>{{toMoney(paymentGuide.data.despesa)}}</div>
                                </div>
                                <!-- <div> -->
                                    <!-- <div>Lucro Líquido</div> -->
                                    <!-- <div>{{toMoney(paymentGuide.data.lucro_liquido)}}</div> -->
                                <!-- </div> -->
                                <div>
                                    <div>Recebido</div>
                                    <div>{{toMoney(paymentGuide.data.recebido)}}</div>
                                </div>
                                <div>
                                    <div>Enviado</div>
                                    <div>{{toMoney(paymentGuide.data.enviado)}}</div>
                                </div>
                                <div>
                                    <div v-if="paymentGuide.data.haver > 0">Dev</div>
                                    <div v-else>Haver</div>
                                    <div>{{toMoney(paymentGuide.data.haver)}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </SideBar>
        
        <!-- Modal para lancamento de saldo  -->
        <SideBar :showCloseIcon="false" v-model:visible="visible.postBalance" position="bottom" class="p-sidebar-md">
            <div style="text-align: right">
                <i @click="visible.postBalance = false" style="padding: 5px" class="pi pi-times"></i>
            </div>
            <div>
                <b><i class="pi pi-user"></i> {{ postBalanceForm.name }}</b> <span :class="{'negative':postBalanceForm.balance < 0}">( {{ toMoney(postBalanceForm.balance) }} )</span>
            </div>
            <p>Insira o valor que deseja lançar.</p>
            <div class="p-inputgroup">
                <span class="p-inputnumber p-component p-inputwrapper">
                    <input v-model.lazy="postBalanceForm.value" pattern="[0-9]*" v-money="money" autofocus  class="p-inputtext p-component p-inputnumber-input p-inputnumber-input" type="text" inputmode="numeric" placeholder="Valor">
                </span>
                <Button :disabled="loading.postBalance" icon="pi pi-check" class="p-button-success" @click="postBalance()"/>
            </div>
            <ProgressBar v-if="loading.postBalance" style="height:10px" mode="indeterminate" />
        </SideBar>

        <!-- Modal para impressão -->
        <SideBar v-model:visible="visible.print" position="top" class="print-sidebar">
            <div class="print-setup">
                <div class="settings">
                    <div>
                        Tipo de Impressão
                        <SelectButton v-model="print.type" :options="print.types" />
                        <SelectButton v-model="print.printSize" :options="print.printSizes" />
                    </div>
                </div>
                <Button class="connected" v-if="print.connected && print.type == 'Tipo 2'">
                    <div>Conectado</div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                </Button>

                <Button class="connect" v-else-if="print.type == 'Tipo 2'" @click="connect">
                    <div>Conectar</div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M196.48 260.023l92.626-103.333L143.125 0v206.33l-86.111-86.111-31.406 31.405 108.061 108.399L25.608 368.422l31.406 31.405 86.111-86.111L145.84 512l148.552-148.644-97.912-103.333zm40.86-102.996l-49.977 49.978-.338-100.295 50.315 50.317zM187.363 313.04l49.977 49.978-50.315 50.316.338-100.294z"/></svg>
                </Button>
                <div v-if="(print.type == 'Tipo 2' && print.connected) || print.type == 'Tipo 1' || print.type == 'Tipo 3'">
                    <Button :disabled="sessions.length <= 0" @click="handlePrint">Imprimir &nbsp; <i class="pi pi-print"></i></Button>
                </div>
                <div>
                    <div v-if="!loading.bluetooth">
                        <div v-if="print.type == 'Tipo 1'"><br><hr>Lembre-se de ter instalado e configurado o app <a target="_blank" href="https://play.google.com/store/apps/details?id=ru.a402d.rawbtprinter">RawBT</a></div>
                        <div v-if="print.type == 'Tipo 2' && !print.connected"><br><hr>Por favor conecte-se a uma impresssora para continuar</div>
                        <div v-if="print.type == 'Tipo 3'"><br><hr>Lembre-se de ter instalado e configurado o app <a target="_blank" href="https://uniaoweb.bancaunica.com/buprinter.apk">BUPrinter</a></div>
                    </div>
                    <div v-else><i class="pi pi-spinner pi-spin"></i> Conectando...</div>
                </div>
            </div>

        </SideBar>
    </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Skeleton from 'primevue/skeleton'
import Button from 'primevue/button'
import SideBar from 'primevue/sidebar'
import ProgressBar from "primevue/progressbar";
import * as api from '@/services/api'
import {formatMoney} from '@/services/helper'
import SelectButton from 'primevue/selectbutton';
import BTP from "@/plugins/BluetoothThermalPrinter";
import EscPosEncoder from '@/plugins/esc-pos-encoder/src/esc-pos-encoder'
import {VMoney} from 'v-money-no-negative';
import Canvas from "@/plugins/Canvas";
import Calendar from 'primevue/calendar'
import axios from 'axios';

export default {
    name: "RelatorioPrincipal",
    components:{
        DataTable,
        Column,
        Skeleton,
        Button,
        SideBar,
        ProgressBar,
        SelectButton,
        Calendar
    },
    data(){
        return {
            sessions: [],
            filteredSessions: [],
            loading: {
                table: true,
                postBalance: false,
                bluetooth: false,
            },
            visible: {
                postBalance: false,
                print: false,
                paymentGuide: false,
            },
            postBalanceForm: {
                id: 0,
                value: 0,
            },
            paymentGuide:{
                selectedDate:false,
                calendarVisible: false,
                date: '',
                session: {},
                data: {},
                loading: false,
            },
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 0,
                masked: false
            },
            print: {
                connected: false,
                types:['Tipo 1','Tipo 2', 'Tipo 3'],
                type: 'Tipo 1',
                printSize: '58MM',
                printSizes: ['58MM', '80MM'],
                characteristics: null,
                gattServer: null,
                // 'guide' || 'balance'
                reference: ''
            },
            filter: {
                name: ''
            },
            reportType: 'Geral',
            page: 1,
            perpage: 50,
            totalRecords: 0,
        }
    },
    created(){
        // Carrega os dados das seções, com Nome e Saldo
        this.loadSessions()
    },
    methods: {
        onSort(event) {
            const field = event.sortField
            const order = event.sortOrder
            return this.filteredSessions.sort((a, b) => {
                if (field == 'name') {
                    return order === -1 ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
                }
                const compareResult = a[field] - b[field];
                return order === -1 ? -compareResult : compareResult;
            });
        },
        onPage(event) {
            if(this.product() !== 'BU') return

            this.page = event.page + 1
            this.perpage = event.rows
            this.loadSessions()
        },

        product(){
            return api.getProduct()?.name;
        },
        loadPointGuide(){
            this.paymentGuide.calendarVisible = false
            this.paymentGuide.selectedDate = true
            this.paymentGuide.data = {}

            let params = {
                id: this.paymentGuide.session.id,
                date: this.paymentGuide.date,
            }

            this.paymentGuide.loading = true
            api.getPointGuide(params)
                .then((response)=>{
                    this.paymentGuide.data = response
                }).catch((e)=>{
                    console.log(e);
                    this.selectedDate = false
                    this.error(e)
                }).finally(()=>{
                    this.paymentGuide.loading = false
                })
        },
        showPaymentGuide(session) {
            /** Mostra o Modal de Guia de Pagamento */

            this.paymentGuide.session = session
            this.visible.paymentGuide = true
        },
        showPostBalance(session) {
            /** Mostra o Modal de Lancar saldo */

            this.postBalanceForm = session
            this.visible.postBalance = true
        },

        postBalance(){
            /** Lanca o saldo */

            this.loading.postBalance = true

            // Retira o R$ do input de dinheiro v-money
            const valueFormatted = parseFloat(this.postBalanceForm.value.replace(/[^,\d]/g,'').replace(',','.'))

            let data = {
                id: this.postBalanceForm.id,
                value: valueFormatted,
                name: this.postBalanceForm.name,
                reportType: this.reportType
            }

            // Validação do 'tipo' de saldo CREDITO ou DÉBITO
            if (this.postBalanceForm.balance < 0) {
                data.type = 'Crédito'
            } else {
                data.type = 'Débito'
            }

            api.postBalance(data)
            .then((msg)=>{
                //Recarrega os dados das seções após o lançamento
                this.loadSessions()
                this.visible.postBalance = false

                this.success(msg)
            }).catch((msg)=>{
                this.error(msg)
            }).finally(()=>{
                this.loading.postBalance = false
            })
        },

        loadSessions(){
            /** Carrega os dados das seções, com Nome e Saldo */

            var params = {
                perpage: this.perpage,
                page: this.page,
                reportType: this.reportType
            }

            this.loading.table = true
            api.getSessions(params)
                .then((e)=>{
                    this.totalRecords = e.totalRecords
                    this.sessions = e.data
                    this.filteredSessions = e.data
                })
                .catch((msg)=>{
                    this.error(msg)
                })
                .finally(() => {
                    this.loading.table = false
                })
        },
        toMoney(val){
            /** Formata o dinheiro para o formato xx.xxx,xx */

            return formatMoney(val);
        },
        connect(){
            this.loading.bluetooth = true
            BTP.connect()
                .then((response)=>{
                    this.print.connected = true
                    this.print.characteristics = response.characteristic
                    this.print.gattServer = response.gattServer
                }).catch(()=>{
                    this.error("Não foi possível conectar")
                }).finally(()=>{
                    this.loading.bluetooth = false
                })
        },
        handlePrint(){
            switch (this.print.type){
                case "Tipo 1":
                    this.printType1()
                    break;
                case "Tipo 2":
                    this.printType2()
                    break;
                case "Tipo 3":
                    this.printType3()
                    break;
            }
        },
        printType1(){
            // COM RAWBT

            // Se estiver conectado, desconecta para que o app funcione
            if (this.print.gattServer){
                try{
                    this.print.gattServer.disconnect()
                    this.print.connected = false
                    this.print.characteristics = null
                    this.print.gattServer = null
                }catch (e) {
                    console.log(e)
                }
            }
            let canvas = false;
            switch (this.print.reference) {
                case 'balance':
                    canvas = this.printBalanceType1()
                    break;
                
                case 'guide':
                    canvas = this.printGuideType1()
                    break;
            
                default:
                    this.error("Erro ao gerar impressão.");
                    return;
            }

            canvas.element.toBlob(blob => {
                const formData = new FormData();
                formData.append('attachment', blob);

                axios.post('https://apiaws.bancaunica.com/api/images/stores3', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                .then((response) => {
                    const url = `intent:${encodeURI(response.data.url)}#Intent;component=ru.a402d.rawbtprinter.activity.PrintDownloadActivity;package=ru.a402d.rawbtprinter;end;`
                    document.location=url
                    this.loading.print = false
                })
                .catch((err) => {
                    console.log(err)
                });
            });  
          
        },
        printType2(){
            // COM ESC_POS_ENCONDER

            let bluetooth = this.print.characteristics
            if (!bluetooth || !bluetooth.uuid) {
                this.print.connected = false
                return;
            }
            let chunks = []
            switch (this.print.reference) {
                case 'balance':
                    chunks = this.printBalanceType2()
                    break;
                
                case 'guide':
                    chunks = this.printGuideType2()
                    break;
            
                default:
                    this.error("Erro ao gerar impressão.");
                    return;
            }

            function printChunk(i = 0) {
                if (i >= chunks.length) return;
                BTP.Print(bluetooth, chunks[i])
                    .then(()=>{
                        printChunk(i+1)
                    }).catch(()=>{
                })
            }

            printChunk()

        },
        printType3(){
            // COM BUPRINTER

            // Se estiver conectado, desconecta para que o app funcione
            if (this.print.gattServer){
                try{
                    this.print.gattServer.disconnect()
                    this.print.connected = false
                    this.print.characteristics = null
                    this.print.gattServer = null
                }catch (e) {
                    console.log(e)
                }
            }
            let canvas = false;
            switch (this.print.reference) {
                case 'balance':
                    canvas = this.printBalanceType3()
                    break;
                
                case 'guide':
                    canvas = this.printGuideType3()
                    break;
            
                default:
                    this.error("Erro ao gerar impressão.");
                    return;
            }

            canvas.toBlob(blob => {
                const formData = new FormData();
                formData.append('attachment', blob);

                axios.post('https://apiaws.bancaunica.com/api/images/stores3', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                .then((response) => {
                    const encodedUrl = btoa(response.data.url + `&width=${canvas.width}`);
                    document.location=`buprinter://print/${encodedUrl}` 
                })
                .catch((err) => {
                    console.log(err)
                });
            });    
            
            
        },
        printBalanceType1(){
            const canvasWidth = this.print.printSize == '58MM' ? 376 : 576

            let canvas = new Canvas(canvasWidth, 8)
                .line()
                .text('Relatório','center', 36)
                .text(new Date().toLocaleString(),'center', 38)
                .line()
                
            this.sessions.forEach((item)=>{
                canvas.text("Ponto: " + item.name, 'left', 38)
                      .text('Saldo: ' + item.balance, 'left', 38)
                      .line()
            })
            canvas.text('Total: '+ this.toMoney(this.totalBalance), 'left', 38)
            canvas.addHeight()
            return canvas;
        },
        
        printBalanceType2(){
            let encoder = new EscPosEncoder()
            let printer = encoder.initialize().codepage('cp860')

            let header = [
                printer.rule().encode(),
                printer.codepage('cp860')
                    .align('center')
                    .line('Relatorio',null,2,2)
                    .line(new Date().toLocaleString())
                    .encode(),
                printer.rule().encode(),
            ]

            let content = []

            this.sessions.forEach((item)=>{
                content.push(printer.codepage('cp860').line("Ponto: "+ item.name).line('Saldo: '+ item.balance).encode())
                content.push(printer.rule().encode())
            })
            
            let footer = [
                printer.line("Total: "+this.toMoney(this.totalBalance), 'left', 38).encode(),
                printer.newline(),
                printer.newline(),
            ]

            let chunks = [
                ...header,
                ...content,
                ...footer
            ]

            return chunks
        },
        printBalanceType3(){
            const canvasWidth = this.print.printSize == '58MM' ? 376 : 576

            let canvas = new Canvas(canvasWidth, 8)
                .line()
                .text('Relatório','center', 36)
                .text(new Date().toLocaleString(),'center', 38)
                .line()
                
            this.sessions.forEach((item)=>{
                canvas.text("Ponto: " + item.name, 'left', 38)
                      .text('Saldo: ' + item.balance, 'left', 38)
                      .line()
            })
            canvas.text('Total: '+ this.toMoney(this.totalBalance), 'left', 38)
            canvas.addHeight(60)
            return canvas.getElement();
        },
        printGuideType1(){
            console.log(this.paymentGuide)

            const canvasWidth = this.print.printSize == '58MM' ? 376 : 576

            let canvas = new Canvas(canvasWidth, 8)
                .line()
                .text('GUIA DE PAGAMENTO','center', 30)
                .addHeight()
                .text(new Date(this.paymentGuide.date).toLocaleDateString(),'center')
                .line()
                .text(this.paymentGuide.session.name,'center')
                .line()
            
            this.paymentGuide.data.vendaPorExtracao.forEach((e) => {
                canvas.text('Extração: '+e.name)
                canvas.text('Comissão: '+this.toMoney(e.comissao_total))
                canvas.text('Venda: '+this.toMoney(e.venda_total))
                canvas.line()
            })

            this.paymentGuide.data.premiadas.forEach((e) => {
                canvas.text('Extração :'+e.name)
                canvas.text('Ticket :'+e.id)
                canvas.text('Prêmio :'+e.total_value)
            })

            if(this.paymentGuide.data.vendaPorExtracao.length == 0){
                canvas.text("Sem Vendas")
                canvas.addHeight()
            }
            if(this.paymentGuide.data.premiadas.length == 0){
                canvas.text("Sem Prêmios")
                canvas.addHeight()
            }

            canvas.line()

            canvas.text('Dev Anterior: ','left',38,this.toMoney(this.paymentGuide.data.dev_anterior))
            canvas.text('Vendas: ','left',38,this.toMoney(this.paymentGuide.data.vendas))
            canvas.text('Cancelamento: ','left',38,this.toMoney(this.paymentGuide.data.cancelamentos))
            canvas.text('Comissão: ','left',38,this.toMoney(this.paymentGuide.data.comissao))
            canvas.text('Prêmios: ','left',38,this.toMoney(this.paymentGuide.data.premios))
            canvas.text('Cana: ','left',38,this.toMoney(this.paymentGuide.data.cana))
            // canvas.text('Lucro Bruto: ','left',38,this.toMoney(this.paymentGuide.data.lucro_bruto))
            canvas.text('Reclamação: ','left',38,this.toMoney(this.paymentGuide.data.reclamacao))
            canvas.text('Despesa: ','left',38,this.toMoney(this.paymentGuide.data.despesa))
            // // canvas.text('Lucro Líquido: ','left',38,this.toMoney(this.paymentGuide.data.lucro_liquido))
            canvas.text('Recebido: ','left',38,this.toMoney(this.paymentGuide.data.recebido))
            canvas.text('Enviado: ','left',38,this.toMoney(this.paymentGuide.data.enviado))
            if (this.paymentGuide.data.haver > 0) {
                canvas.text('Dev: ','left',38,this.toMoney(this.paymentGuide.data.haver))
            } else {
                canvas.text('Haver: ','left',38,this.toMoney(this.paymentGuide.data.haver))
            }

            canvas.addHeight(60)
            return canvas;
        },
        printGuideType2(){
            let encoder = new EscPosEncoder()
            let printer = encoder.initialize().codepage('cp860')

            let header = [
                printer.rule().encode(),
                printer.codepage('cp860')
                    .align('center')
                    .line('GUIA DE PAGAMENTO',null,1,1)
                    .line(new Date(this.paymentGuide.date).toLocaleDateString())
                    .line(this.paymentGuide.session.name,'center')
                    .encode(),
                printer.rule().encode(),
            ]

            let content = []
            this.paymentGuide.data.vendaPorExtracao.forEach((e)=>{
                content.push(
                    printer.codepage('cp860').line('Extração: '+e.name)
                       .line('Comissão: '+this.toMoney(e.comissao_total))
                       .line('Venda: '+this.toMoney(e.venda_total))
                       .newline().encode()
                )
            })

            this.paymentGuide.data.premiadas.forEach((e)=>{
                content.push(
                    printer.codepage('cp860').line('Extração :'+e.name)
                        .line('Ticket :'+e.id)
                        .line('Prêmio :'+e.total_value).newline().encode()
                )
            })

            if(this.paymentGuide.data.vendaPorExtracao.length == 0){
                content.push(printer.rule().line("Sem Vendas")
                    .newline().encode())
                
            }
            if(this.paymentGuide.data.premiadas.length == 0){
                content.push(printer.rule().codepage('cp860').line("Sem Prêmios")
                    .newline().encode())
            }

            content.push(printer.rule().encode())
            
            content.push(
                printer.codepage('cp860')
                    .text('Dev Anterior: ').bold(true).text(this.toMoney(this.paymentGuide.data.dev_anterior)).bold(false).newline()
                    .text('Vendas: ').bold(true).text( this.toMoney(this.paymentGuide.data.vendas)).bold(false).newline()
                    .text('Cancelamento: ').bold(true).text( this.toMoney(this.paymentGuide.data.cancelamentos)).bold(false).newline()
                    .text('Comissão: ').bold(true).text( this.toMoney(this.paymentGuide.data.comissao)).bold(false).newline()
                    .text('Prêmios: ').bold(true).text( this.toMoney(this.paymentGuide.data.premios)).bold(false).newline()
                    .text('Cana: ').bold(true).text( this.toMoney(this.paymentGuide.data.cana)).bold(false).newline()
                    .encode()
            )
            content.push(
                printer.codepage('cp860')
                    .text('Reclamação: ').bold(true).text(this.toMoney(this.paymentGuide.data.reclamacao)).bold(false).newline()
                    .text('Despesa: ').bold(true).text(this.toMoney(this.paymentGuide.data.despesa)).bold(false).newline()
                    .text('Recebido: ').bold(true).text(this.toMoney(this.paymentGuide.data.recebido)).bold(false).newline()
                    .text('Enviado: ').bold(true).text(this.toMoney(this.paymentGuide.data.enviado)).bold(false).newline()
                    .text(this.paymentGuide.data.haver > 0 ? 'Dev: ': 'Haver: ').bold(true).text(this.toMoney(this.paymentGuide.data.haver)).bold(false).newline()
                    .newline().encode()
            )
            
            let footer = [
                printer.newline().encode(),
            ]

            let chunks = [
                ...header,
                ...content,
                ...footer
            ]

            return chunks
        },
        printGuideType3(){
            const canvasWidth = this.print.printSize == '58MM' ? 376 : 576

            let canvas = new Canvas(canvasWidth, 8)
                .line()
                .text('GUIA DE PAGAMENTO','center',30)
                .addHeight()
                .text(new Date(this.paymentGuide.date).toLocaleDateString(),'center', 38)
                .line()
                .text(this.paymentGuide.session.name,'center', 38)
                .line()
            
            this.paymentGuide.data.vendaPorExtracao.forEach((e)=>{
                canvas.text('Extração: '+e.name, 'left', 38)
                canvas.text('Comissão: '+this.toMoney(e.comissao_total), 'left', 38)
                canvas.text('Venda: '+this.toMoney(e.venda_total), 'left', 38)
                canvas.line()
            })

            this.paymentGuide.data.premiadas.forEach((e)=>{
                canvas.text('Extração :'+e.name, 'left', 38)
                canvas.text('Ticket :'+e.id, 'left', 38)
                canvas.text('Prêmio :'+e.total_value, 'left', 38)
            })

            if(this.paymentGuide.data.vendaPorExtracao.length == 0){
                canvas.text("Sem Vendas", 'left', 38)
                canvas.addHeight()
            }
            if(this.paymentGuide.data.premiadas.length == 0){
                canvas.text("Sem Prêmios", 'left', 38)
                canvas.addHeight()
            }

            canvas.line()

            canvas.text('Dev Anterior: ','left',38,this.toMoney(this.paymentGuide.data.dev_anterior))
            canvas.text('Vendas: ','left',38,this.toMoney(this.paymentGuide.data.vendas))
            canvas.text('Cancelamento: ','left',38,this.toMoney(this.paymentGuide.data.cancelamentos))
            canvas.text('Comissão: ','left',38,this.toMoney(this.paymentGuide.data.comissao))
            canvas.text('Prêmios: ','left',38,this.toMoney(this.paymentGuide.data.premios))
            canvas.text('Cana: ','left',38,this.toMoney(this.paymentGuide.data.cana))
            // canvas.text('Lucro Bruto: ','left',38,this.toMoney(this.paymentGuide.data.lucro_bruto))
            canvas.text('Reclamação: ','left',38,this.toMoney(this.paymentGuide.data.reclamacao))
            canvas.text('Despesa: ','left',38,this.toMoney(this.paymentGuide.data.despesa))
            // // canvas.text('Lucro Líquido: ','left',38,this.toMoney(this.paymentGuide.data.lucro_liquido))
            canvas.text('Recebido: ','left',38,this.toMoney(this.paymentGuide.data.recebido))
            canvas.text('Enviado: ','left',38,this.toMoney(this.paymentGuide.data.enviado))
            if (this.paymentGuide.data.haver > 0) {
                canvas.text('Dev: ','left',38,this.toMoney(this.paymentGuide.data.haver))
            } else {
                canvas.text('Haver: ','left',38,this.toMoney(this.paymentGuide.data.haver))
            }

            canvas.addHeight(60)
            return canvas.getElement();
        },

    },
    computed: {
        totalBalance() {
            return (this.filteredSessions.map(e => parseFloat(e.balance)).reduce((anterior, novo)=>{ return anterior + novo },0)).toFixed(2);
            // Soma todos os saldos e retorna o total
        },
    },
    directives: {money: VMoney},
    watch:{
        'paymentGuide.session':function (){
            this.paymentGuide.selectedDate = false
        },
        'filter.name':function (){
            if (!this.filter.name) {
                this.filteredSessions = this.sessions
                return 
            }
            
            this.filteredSessions = this.sessions.filter((e)=>{
                return e.name.toUpperCase().includes(this.filter.name.toUpperCase())
            })
            
            return;
        }
    }
}
</script>

<style lang="scss" scoped>
.payment-guide-container{
    .empty{
        text-align: center;
        padding: 10px 5px;
    }
    .header{
        text-transform: uppercase;
        font-family: 'Poppins',sans-serif;
        display: flex;
        justify-content: space-between;
        align-content: center;
        padding: 20px 0;
        border-bottom: 1px solid #0002;
        margin-bottom: 10px;
        >div{
            display: flex;
            align-content: center;
            flex-wrap: wrap;
        }
        i {
            cursor:pointer;
            font-size: 18px;
            margin-left: 15px;
            padding: 10px;
            border-radius: 20px;
            // background-color: #177883;
            // color: white;

            &:last-child{
                background-color:transparent;
                color: revert;
            }
        }
    }
    .content{
        font-family: 'Poppins',sans-serif;
        h4{
            margin-left: 10px;
            margin-bottom: 5px;
        }
        .top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
        }        
        .p-calendar{
            display: block;
        }

        .resumo > div{
            border-radius: 5px;
            // border: 1px solid #177883;
            box-shadow: 0 0 5px #0008;
            &>div{
                display: flex;
                justify-content: space-between;
                padding: 8px 14px;
                &:nth-child(even){
                    background-color: #17788322;
                }
            }
        }
    }
}

.grow-enter-active,
.grow-leave-active {
    transition: max-height 400ms ease;
    max-height: 500px;
}

.grow-enter-from,
.grow-leave-to {
    max-height: 0;
}

.p-datatable:deep(.p-datatable-tbody){
    // Célula dos botões da tabela
    td:last-child{
        padding: 0;
    }
}

.buttons{
    display: flex;
    justify-content: center;
    .p-button{
        width: 30px;

        &:first-child{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            background-color: #1f9fad;
        }
        &:last-child{
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

@import "../styles/variables";
.print-setup {
    margin: 5px 0;
    font-family: 'Poppins',sans-serif;
    color: $color;
    text-align: center;
    
    .p-button{
        width: 100% !important;
        margin-bottom: 10px;
    }

    .pi,svg{
        margin-left: auto;
    }
    
    .connect,.connected {
        padding-right: 8px;
        text-transform: uppercase;
        width: 150px;
        height: 50px;

        >div{
            margin-right: 10px;
        }

        svg{
            fill: white;
            width: 15px;
        }

        &.connected{
            background-color: green;
            margin-bottom: 10px;
        }
    }

    .settings{
        margin-top: 20px;
        display: flex;
        gap: 20px;
        flex-direction: column;

        .p-selectbutton{
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
        }
    }
}

.header-container{
    display: flex;
    justify-content: space-between;
    gap: 25px;
    .search{
        flex: 1;
        
        input {
            width: 100%;
        }
    }
}

.button-set{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    gap: 10px;
    
    >div{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 17px;
        font-family: 'Poppins', sans-serif;
    }
    
    button{
        border-radius: 10px;
        
        &:deep(.pi){
            font-size: 17px;
        }
        &.gray{
            background-color: lighten($color,10%);
        }
    }

}

.negative{
    color: red;
    font-weight: bold;
}

</style>
<style lang="scss">
.print-sidebar.p-sidebar-top{
    height: 55vh;

    div.p-button{
        width: 100% !important;
    }
    
    .p-sidebar-content{
        padding: 0 50px;
    }
}

.p-sidebar-bottom.payment-guide{
    height: 90vh;

    .p-sidebar-header{
        display: none;
    }
}

.p-selectbutton {
    display: flex;
    margin-bottom: 1rem;
    .p-button {
        flex:1;
    }
}
</style>
